/* Scrollbar CSS */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(12, 11, 32, 1);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #344675;
  border-radius: 2px;
}

.text-success {
  color: #00F2C3 !important;
}

.text-error {
  color: #FD5D93 !important;
}

/* Page Loader CSS */
#page-preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(23, 25, 65, 0.95);
  z-index: 100000;
}

#page-preloader .loader-ring,
#page-preloader .loader-ring2 {
  border: 60px solid #FFFFFF;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  margin: -60px 0 0 -60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#page-preloader .loader-ring,
#page-preloader .loader-ring2 {
  -webkit-animation: pulsate 1.6s linear infinite;
  -moz-animation: pulsate 1.6s linear infinite;
  -ms-animation: pulsate 1.6s linear infinite;
  animation: pulsate 1.6s linear infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

#page-preloader .loader-ring2 {
  -webkit-animation-delay: -.8s;
  -moz-animation-delay: -.8s;
  -ms-animation-delay: -.8s;
  animation-delay: -.8s;
}

@-webkit-keyframes pulsate {
  0% {
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.0;
  }
}

@-moz-keyframes pulsate {
  0% {
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.0;
  }
}

@-ms-keyframes pulsate {
  0% {
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.0;
  }
}

@keyframes pulsate {
  0% {
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.0;
  }
}

/* Toast CSS */
#toast-container>div {
  opacity: 1 !important;
}

.toast-success {
  background-color: #4CAF50 !important;
}

.toast-error {
  background-color: #FD5D93 !important;
}

.toast-info {
  background-color: #1D8CF8 !important;
}

.toast .toast-close-button {
  box-shadow: none;
  font-size: 24px;
}

.toast .toast-title {
  font-weight: 600;
}

.toast .toast-message {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
}

/* checkoutModal CSS */
.checkoutModal {
  background: rgba(23, 25, 65, 1);
  color: #FFFFFF;
  padding-right: 0px !important;
  text-align: center;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  margin: 0px !important;
  transform: none !important;
  z-index: 99999;
}

.checkoutModal .modal-content {
  background: rgba(23, 25, 65, 1);
  padding: 125px 0px;
  width: inherit;
  height: 100%;
}

.checkoutModal .modal-header,
.checkoutModal .modal-body,
.checkoutModal .modal-footer {
  border: none;
  display: block;
  max-width: 425px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px;
}

.checkoutModal .modal-header .close {
  color: rgba(52, 70, 117, 1);
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 48px !important;
  font-weight: 400;
  opacity: .75;
  outline: none;
  text-shadow: 0.5px 0.5px 0px #FFFFFF;
}

.checkoutModal .modal-header .close:hover {
  transform: scale(1.1);
}

.checkoutModal .modal-header img {
  max-width: 36px;
}

.checkoutModal .modal-header .modal-title .title {
  background: -webkit-linear-gradient(#FFFFFF, #B0B0B0);
  font-size: 20px;
  font-weight: 500;
  padding: 5px 0px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.checkoutModal .modal-body {
  flex: none;
}

.checkoutModal .modal-body input {
  border: 1px solid rgba(52, 70, 117, 1) !important;
  font-size: 14px;
  padding: 8px 12px;
  box-shadow: 0px 0px 2px 0px rgba(52, 70, 117, 1) !important;
}

.checkoutModal .modal-body #applyCouponCodeBtn {
  background: rgba(52, 70, 117, 1) !important;
  border: 1px solid rgba(52, 70, 117, 1) !important;
  font-size: 16px;
  padding: 0px 12px;
  outline: none !important;
}

.checkoutModal .modal-body #paymentInfo>div {
  background: -webkit-linear-gradient(#FFFFFF, #B0B0B0);
  font-size: 16px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.checkoutModal .modal-footer #checkoutPayBtn {
  background: rgba(52, 70, 117, 1) !important;
  border: 1px solid rgba(52, 70, 117, 1) !important;
  color: #FFFFFF;
  font-size: 16px;
  padding: 10px 20px;
  outline: none !important;
}

.checkoutModal .modal-footer #checkoutTemrs {
  background: -webkit-linear-gradient(#FFFFFF, #B0B0B0);
  font-size: 10px;
  letter-spacing: 1px;
  margin-top: 12px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.checkoutModal .spinner-grow {
  display: inline-block;
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #FFFFFF;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
  opacity: 0.90;
  animation: spinner-grow .75s linear infinite;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

/* CUSTOM PAGE CSS */
.info .icon .bg-blob {
  margin-top: -6px;
  margin-left: -6px;
}

.landing-page .page-header .triangle {
  z-index: 0;
}

.dashboard-section #userPlans tbody tr.active {
  background-color: #344675 !important;
}

.dashboard-section input {
  font-size: 14px !important;
}

.card-bot-rental .card-image img {
  max-height: 250px;
}

.carousel-bot-rental img {
  max-height: 350px;
  max-width: 500px;
}

.card-register .card-header .card-title {
  font-size: 4.5em;
}

.error-page {
  background-image: url(../img/dots.png);
  background-size: contain;
  background-color: #171941;
}

.nike-header .logo .logo-image img {
  height: 175px;
  width: 320px;
}

.nike-header .phone {
  max-height: 425px;
}

.support-sites-image-container .coloured-card-img,
.support-sites-image-container .linkedin-btn-img {
  top: -3% !important;
  height: 120px !important;
  max-width: 175px !important;
}

.support-sites-image-container .table-img,
.support-sites-image-container .coloured-card-btn-img {
  top: 45% !important;
  height: 45px !important;
  max-width: 175px !important;
}

.support-sites-image-container .coloured-card-img,
.support-sites-image-container .table-img,
.support-sites-image-container .share-btn-img {
  left: 50% !important;
}

.support-sites-image-container .linkedin-card-btn-img {
  border-radius: 10px;
  float: left;
  position: absolute;
  left: 3%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -webkit-animation-name: Floatingy;
  -moz-animation-name: Floatingy;
  -webkit-animation-duration: 11s;
  -moz-animation-duration: 11s;
}

.support-sites-image-container .share-btn-img,
.support-sites-image-container .linkedin-card-btn-img {
  top: 70% !important;
  height: 50px !important;
  max-width: 200px !important;
}

.support-sites-image-container .share-card-btn-img {
  border-radius: 10px;
  float: left;
  position: absolute;
  left: 3%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
  -webkit-animation-name: Floatingy;
  -moz-animation-name: Floatingy;
  -webkit-animation-duration: 11s;
  -moz-animation-duration: 11s;
}

.support-sites-image-container .share-card-btn-img {
  top: 92% !important;
  height: 45px !important;
  max-width: 250px !important;
}

.btn.btn-discord {
  background: #7289DA !important;
  color: #ffffff;
}

.stripe-page>.container .title,
.stripe-page .title+h4 {
  background: -webkit-linear-gradient(#FFFFFF, #B0B0B0);
  color: rgba(255, 255, 255, 0.9) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stripe-page>.container .title {
  font-size: 2.5em !important;
  font-weight: 700;
  letter-spacing: 2px !important;
}

.stripe-page .title+h4 {
  font-size: 1.25em !important;
  font-weight: 500;
  letter-spacing: 1px !important;
}

#terms-and-conditions h2,
#privacy-policies h2,
#faqs h2 {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 10px;
  text-align: justify;
}

#terms-and-conditions h5,
#privacy-policies h5,
#faqs h5 {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0px;
  margin: 0px;
  text-align: justify;
}

#terms-and-conditions div,
#privacy-policies div,
#faqs div {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.45;
  margin-bottom: 20px;
  text-align: justify;
}

#terms-and-conditions strong,
#privacy-policies strong,
#faqs strong {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.45;
  margin-bottom: 20px;
  text-align: justify;
}

#terms-and-conditions br,
#privacy-policies br,
#faqs br {
  display: block;
  content: "";
  line-height: 1.25;
  margin-top: 8px;
}

#terms-and-conditions span,
#privacy-policies span,
#faqs span {
  display: inline-block;
  line-height: 1.25;
  padding: 5px 0px;
  text-align: justify;
}

@media screen and (max-width:991px) {

  #terms-and-conditions h2,
  #privacy-policies h2,
  #faqs h2,
  #terms-and-conditions h5,
  #privacy-policies h5,
  #faqs h5,
  #terms-and-conditions div,
  #privacy-policies div,
  #faqs div,
  #terms-and-conditions strong,
  #privacy-policies strong,
  #faqs strong,
  #terms-and-conditions span,
  #privacy-policies span,
  #faqs span {
    text-align: left;
  }
}
